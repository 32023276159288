export default {

    data: () => ({
        model: ' '
    }),
    methods: {
        init(v) {
            this.model = v;
            return true;

        },
        required() {

            if (this.model) {
                if (this.model.trim().length < 1) {

                    return 'Fields is required';
                }
                return true;
            }
            return 'Fields is required';

        },
        requiredNumber() {

            if (this.model) {
                if (this.model.length < 1) {

                    return 'Fields is required';
                }
                return true;
            }
            return 'Fields is required';

        },
        requiredSelect() {

            if (this.model) {
                if (this.model.length < 1) {

                    return 'Fields is required';
                }
                return true;
            }
            return 'Fields is required';

        },
        maxlen(len) {

            if (this.model) {
                if (this.model.trim().length > len) {
                    return 'Fields must be less than ' + len + ' characters';
                }
                return true;
            }
            return true;
        },
        valid_email() {
            const re = /.+@.+\..+/;

            if (this.model) {
                if (!re.test(this.model)) {
                    return 'E-mail must be valid';
                }
                return true;
            }
            return true;
        },
        digit_range(from, to) {
            if (this.model) {

                if (this.model >= from && this.model <= to) {
                    return true;
                }
                return 'Fields should between ' + from + ' and ' + to;
            }
            return true;
        }
    }
}